import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Watch } from 'vue-property-decorator'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'

@Component({
  name: 'GtrEventIndexView'
})
export default class GtrRegistrationModuleIndexView extends GtrSuper {
  created () {
    this.$emit('update:layout', GtrModuleLayout)
    // this.$bus.$emit('update:menu', Module.REGISTRATION)
  }

  @Watch('$route')
  onRouterChange () {
    // this.$bus.$emit('update:menu', Module.REGISTRATION)
  }
}
